/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
/* CSS from flexbox-grid, modified to use sass variables */
.st_pagewrapper {
  width: calc(100% - var(--st-spacer-xl));
  max-width: var(--st-grid-page-fullwidth);
  margin-left: auto;
  margin-right: auto; }
.st_container-fluid,
.st_container {
  margin-right: auto;
  margin-left: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_container-fluid {
  padding-right: var(--st-grid-margin-outer);
  padding-left: var(--st-grid-margin-outer); }
.st_row {
  --st-grid-component-row-cols-xs: 12;
  --st-grid-component-row-col-width-xs: calc(100% / var(--st-grid-component-row-cols-xs, 12));
  --st-grid-component-row-cols-sm: 12;
  --st-grid-component-row-col-width-sm: calc(100% / var(--st-grid-component-row-cols-sm, 12));
  --st-grid-component-row-cols-md: 12;
  --st-grid-component-row-col-width-md: calc(100% / var(--st-grid-component-row-cols-md, 12));
  --st-grid-component-row-cols-lg: 12;
  --st-grid-component-row-col-width-lg: calc(100% / var(--st-grid-component-row-cols-lg, 12));
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: var(--st-grid-gutter-compensation);
  margin-left: var(--st-grid-gutter-compensation); }
.st_row.st_reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }
.st_col.st_reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }
.st_col-xs,
.st_col-xs-1,
.st_col-xs-2,
.st_col-xs-3,
.st_col-xs-4,
.st_col-xs-5,
.st_col-xs-6,
.st_col-xs-7,
.st_col-xs-8,
.st_col-xs-9,
.st_col-xs-10,
.st_col-xs-11,
.st_col-xs-12 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding-right: var(--st-grid-gutter-half);
  padding-left: var(--st-grid-gutter-half); }
.st_col-xs {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  max-width: 100%; }
.st_col-xs-1 {
  -ms-flex-preferred-size: var(--st-grid-component-row-col-width-xs);
      flex-basis: var(--st-grid-component-row-col-width-xs);
  max-width: var(--st-grid-component-row-col-width-xs); }
.st_col-xs-2 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 2);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 2);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 2); }
.st_col-xs-3 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 3);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 3);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 3); }
.st_col-xs-4 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 4);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 4);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 4); }
.st_col-xs-5 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 5);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 5);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 5); }
.st_col-xs-6 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 6);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 6);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 6); }
.st_col-xs-7 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 7);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 7);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 7); }
.st_col-xs-8 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 8);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 8);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 8); }
.st_col-xs-9 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 9);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 9);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 9); }
.st_col-xs-10 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 10);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 10);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 10); }
.st_col-xs-11 {
  -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-xs) * 11);
      flex-basis: calc(var(--st-grid-component-row-col-width-xs) * 11);
  max-width: calc(var(--st-grid-component-row-col-width-xs) * 11); }
.st_col-xs-12 {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  max-width: 100%; }
.st_col-xs-offset-1 {
  margin-left: var(--st-grid-component-row-col-width-xs); }
.st_col-xs-offset-2 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 2); }
.st_col-xs-offset-3 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 3); }
.st_col-xs-offset-4 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 4); }
.st_col-xs-offset-5 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 5); }
.st_col-xs-offset-6 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 6); }
.st_col-xs-offset-7 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 7); }
.st_col-xs-offset-8 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 8); }
.st_col-xs-offset-9 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 9); }
.st_col-xs-offset-10 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 10); }
.st_col-xs-offset-11 {
  margin-left: calc(var(--st-grid-component-row-col-width-xs) * 11); }
[dir="ltr"] .st_start-xs {
  text-align: left; }
[dir="rtl"] .st_start-xs {
  text-align: right; }
.st_start-xs {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
.st_center-xs {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center; }
[dir="ltr"] .st_end-xs {
  text-align: right; }
[dir="rtl"] .st_end-xs {
  text-align: left; }
.st_end-xs {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
.st_top-xs {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
.st_middle-xs {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.st_bottom-xs {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end; }
.st_around-xs {
  -ms-flex-pack: distribute;
      justify-content: space-around; }
.st_between-xs {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
.st_first-xs {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }
.st_last-xs {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }
@media (min-width: 48rem) {
  .st_container {
    width: var(--st-grid-container-sm); }
  .st_col-sm,
  .st_col-sm-1,
  .st_col-sm-2,
  .st_col-sm-3,
  .st_col-sm-4,
  .st_col-sm-5,
  .st_col-sm-6,
  .st_col-sm-7,
  .st_col-sm-8,
  .st_col-sm-9,
  .st_col-sm-10,
  .st_col-sm-11,
  .st_col-sm-12 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: var(--st-grid-gutter-half);
    padding-left: var(--st-grid-gutter-half); }
  .st_col-sm {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%; }
  .st_col-sm-1 {
    -ms-flex-preferred-size: var(--st-grid-component-row-col-width-sm);
        flex-basis: var(--st-grid-component-row-col-width-sm);
    max-width: var(--st-grid-component-row-col-width-sm); }
  .st_col-sm-2 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 2);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 2);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 2); }
  .st_col-sm-3 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 3);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 3);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 3); }
  .st_col-sm-4 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 4);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 4);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 4); }
  .st_col-sm-5 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 5);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 5);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 5); }
  .st_col-sm-6 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 6);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 6);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 6); }
  .st_col-sm-7 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 7);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 7);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 7); }
  .st_col-sm-8 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 8);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 8);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 8); }
  .st_col-sm-9 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 9);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 9);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 9); }
  .st_col-sm-10 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 10);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 10);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 10); }
  .st_col-sm-11 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-sm) * 11);
        flex-basis: calc(var(--st-grid-component-row-col-width-sm) * 11);
    max-width: calc(var(--st-grid-component-row-col-width-sm) * 11); }
  .st_col-sm-12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%; }
  .st_col-sm-offset-1 {
    margin-left: var(--st-grid-component-row-col-width-sm); }
  .st_col-sm-offset-2 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 2); }
  .st_col-sm-offset-3 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 3); }
  .st_col-sm-offset-4 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 4); }
  .st_col-sm-offset-5 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 5); }
  .st_col-sm-offset-6 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 6); }
  .st_col-sm-offset-7 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 7); }
  .st_col-sm-offset-8 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 8); }
  .st_col-sm-offset-9 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 9); }
  .st_col-sm-offset-10 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 10); }
  .st_col-sm-offset-11 {
    margin-left: calc(var(--st-grid-component-row-col-width-sm) * 11); }
  [dir="ltr"] .st_start-sm {
    text-align: left; }
  [dir="rtl"] .st_start-sm {
    text-align: right; }
  .st_start-sm {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .st_center-sm {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center; }
  [dir="ltr"] .st_end-sm {
    text-align: right; }
  [dir="rtl"] .st_end-sm {
    text-align: left; }
  .st_end-sm {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .st_top-sm {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .st_middle-sm {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .st_bottom-sm {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .st_around-sm {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .st_between-sm {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .st_first-sm {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .st_last-sm {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; } }
@media (min-width: 64rem) {
  .st_container {
    width: var(--st-grid-container-md); }
  .st_col-md,
  .st_col-md-1,
  .st_col-md-2,
  .st_col-md-3,
  .st_col-md-4,
  .st_col-md-5,
  .st_col-md-6,
  .st_col-md-7,
  .st_col-md-8,
  .st_col-md-9,
  .st_col-md-10,
  .st_col-md-11,
  .st_col-md-12 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: var(--st-grid-gutter-half);
    padding-left: var(--st-grid-gutter-half); }
  .st_col-md {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%; }
  .st_col-md-1 {
    -ms-flex-preferred-size: var(--st-grid-component-row-col-width-md);
        flex-basis: var(--st-grid-component-row-col-width-md);
    max-width: var(--st-grid-component-row-col-width-md); }
  .st_col-md-2 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 2);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 2);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 2); }
  .st_col-md-3 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 3);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 3);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 3); }
  .st_col-md-4 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 4);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 4);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 4); }
  .st_col-md-5 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 5);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 5);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 5); }
  .st_col-md-6 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 6);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 6);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 6); }
  .st_col-md-7 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 7);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 7);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 7); }
  .st_col-md-8 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 8);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 8);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 8); }
  .st_col-md-9 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 9);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 9);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 9); }
  .st_col-md-10 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 10);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 10);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 10); }
  .st_col-md-11 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-md) * 11);
        flex-basis: calc(var(--st-grid-component-row-col-width-md) * 11);
    max-width: calc(var(--st-grid-component-row-col-width-md) * 11); }
  .st_col-md-12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%; }
  .st_col-md-offset-1 {
    margin-left: var(--st-grid-component-row-col-width-md); }
  .st_col-md-offset-2 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 2); }
  .st_col-md-offset-3 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 3); }
  .st_col-md-offset-4 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 4); }
  .st_col-md-offset-5 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 5); }
  .st_col-md-offset-6 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 6); }
  .st_col-md-offset-7 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 7); }
  .st_col-md-offset-8 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 8); }
  .st_col-md-offset-9 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 9); }
  .st_col-md-offset-10 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 10); }
  .st_col-md-offset-11 {
    margin-left: calc(var(--st-grid-component-row-col-width-md) * 11); }
  [dir="ltr"] .st_start-md {
    text-align: left; }
  [dir="rtl"] .st_start-md {
    text-align: right; }
  .st_start-md {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .st_center-md {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center; }
  [dir="ltr"] .st_end-md {
    text-align: right; }
  [dir="rtl"] .st_end-md {
    text-align: left; }
  .st_end-md {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .st_top-md {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .st_middle-md {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .st_bottom-md {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .st_around-md {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .st_between-md {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .st_first-md {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .st_last-md {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; } }
@media (min-width: 75rem) {
  .st_container {
    width: var(--st-grid-container-lg); }
  .st_col-lg,
  .st_col-lg-1,
  .st_col-lg-2,
  .st_col-lg-3,
  .st_col-lg-4,
  .st_col-lg-5,
  .st_col-lg-6,
  .st_col-lg-7,
  .st_col-lg-8,
  .st_col-lg-9,
  .st_col-lg-10,
  .st_col-lg-11,
  .st_col-lg-12 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: var(--st-grid-gutter-half);
    padding-left: var(--st-grid-gutter-half); }
  .st_col-lg {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%; }
  .st_col-lg-1 {
    -ms-flex-preferred-size: var(--st-grid-component-row-col-width-lg);
        flex-basis: var(--st-grid-component-row-col-width-lg);
    max-width: var(--st-grid-component-row-col-width-lg); }
  .st_col-lg-2 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 2);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 2);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 2); }
  .st_col-lg-3 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 3);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 3);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 3); }
  .st_col-lg-4 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 4);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 4);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 4); }
  .st_col-lg-5 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 5);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 5);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 5); }
  .st_col-lg-6 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 6);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 6);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 6); }
  .st_col-lg-7 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 7);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 7);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 7); }
  .st_col-lg-8 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 8);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 8);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 8); }
  .st_col-lg-9 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 9);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 9);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 9); }
  .st_col-lg-10 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 10);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 10);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 10); }
  .st_col-lg-11 {
    -ms-flex-preferred-size: calc(var(--st-grid-component-row-col-width-lg) * 11);
        flex-basis: calc(var(--st-grid-component-row-col-width-lg) * 11);
    max-width: calc(var(--st-grid-component-row-col-width-lg) * 11); }
  .st_col-lg-12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%; }
  .st_col-lg-offset-1 {
    margin-left: var(--st-grid-component-row-col-width-lg); }
  .st_col-lg-offset-2 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 2); }
  .st_col-lg-offset-3 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 3); }
  .st_col-lg-offset-4 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 4); }
  .st_col-lg-offset-5 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 5); }
  .st_col-lg-offset-6 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 6); }
  .st_col-lg-offset-7 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 7); }
  .st_col-lg-offset-8 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 8); }
  .st_col-lg-offset-9 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 9); }
  .st_col-lg-offset-10 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 10); }
  .st_col-lg-offset-11 {
    margin-left: calc(var(--st-grid-component-row-col-width-lg) * 11); }
  [dir="ltr"] .st_start-lg {
    text-align: left; }
  [dir="rtl"] .st_start-lg {
    text-align: right; }
  .st_start-lg {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .st_center-lg {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center; }
  [dir="ltr"] .st_end-lg {
    text-align: right; }
  [dir="rtl"] .st_end-lg {
    text-align: left; }
  .st_end-lg {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .st_top-lg {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .st_middle-lg {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .st_bottom-lg {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .st_around-lg {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .st_between-lg {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .st_first-lg {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .st_last-lg {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; } }
