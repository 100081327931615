/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_radio {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: var(--st-spacer-md);
  height: var(--st-spacer-md);
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  overflow: hidden; }
.st_radio::before {
    border: solid var(--st-color-stainless);
    border-width: 1px;
    content: '';
    width: var(--st-spacer-md);
    height: var(--st-spacer-md);
    font-size: var(--st-font-size-sm);
    display: block;
    border-radius: 50%;
    background: var(--st-color-white);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden; }
.st_radio:checked::before {
    background: var(--st-color-blurple-50);
    background: radial-gradient(circle, var(--st-color-white) 0%, var(--st-color-white) 40%, var(--st-color-blurple-50) 41%, var(--st-color-blurple-50) 80%);
    border-color: var(--st-color-blurple-50); }
.st_radio--disabled {
    opacity: 0.5;
    pointer-events: none; }
.st_radio--disabled::before {
    background: var(--st-color-cool); }
.st_radio--invalid::before {
    border-color: var(--st-color-lava-50);
    background: var(--st-color-lava-05); }
.st_radio--invalid:checked::before {
    border-color: var(--st-color-lava-50);
    background: var(--st-color-lava-50);
    background: radial-gradient(circle, var(--st-color-white) 0%, var(--st-color-white) 40%, var(--st-color-lava-50) 41%, var(--st-color-lava-50) 80%); }
.st_radio:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
