.st_table {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  color: var(--st-color-asphalt);
  display: table;
  border-collapse: collapse;
  background: var(--st-color-white);
  border: 1px solid var(--st-border-color);
  border-spacing: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0; }
  .st_table--fullwidth {
    width: 100%; }
  .st_tablewrapper {
  display: block;
  padding: 0; }
  .st_table__row {
  display: table-row; }
  .st_table__row--hoverable:hover {
    background-color: var(--st-color-cool); }
  .st_tablecell {
  text-align: left;
  display: table-cell;
  font-weight: normal;
  vertical-align: baseline;
  border: 1px solid var(--st-border-color); }
  .st_tablecell--align-center {
    text-align: center; }
  .st_tablecell--align-right {
    text-align: right; }
  .st_tablecell--valign-middle {
    vertical-align: middle; }
  .st_tablecell--valign-top {
    vertical-align: top; }
  .st_tablecell--valign-bottom {
    vertical-align: bottom; }
  .st_table--border-hor .st_tablecell {
  border-left: 0;
  border-right: 0; }
  .st_tablecell.st_tablecell--header {
  text-transform: uppercase;
  font-size: var(--st-font-size-sm);
  color: var(--st-color-asphalt);
  font-weight: var(--st-font-weight-bold);
  border-bottom-width: 2px; }
