/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(--st-spacer-xl) 0;
  font-family: var(--st-font-stack);
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_login__box {
    width: 340px;
    max-width: calc(80vw); }
.st_login__illustration {
    max-width: 180px;
    margin: var(--st-spacer-md) auto; }
.st_login__name {
    font-size: var(--st-font-size-xxl);
    line-height: 1;
    font-weight: var(--st-font-weight-md);
    margin: 0 0 var(--st-spacer-sm);
    text-align: center; }
.st_login__text {
    font-size: var(--st-font-size-md);
    line-height: var(--st-font-line-height-md);
    margin: 0 0 var(--st-spacer-mlg);
    text-align: center;
    color: var(--st-color-asphalt); }
