.st_notification {
  background-color: var(--st-color-white);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'avatar text icon' 'avatar time icon';
  grid-column-gap: var(--st-spacer-md);
  grid-row-gap: var(--st-spacer-xs);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  color: var(--st-color-ink);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-decoration: none; }

.st_notification.st_notification--noavatar {
  grid-template-columns: 1fr auto;
  grid-template-areas: 'text icon' 'time icon'; }

.st_notification.st_notification--noicon {
  grid-template-columns: auto 1fr;
  grid-template-areas: 'avatar text' 'avatar time'; }

.st_notification.st_notification--noavatar.st_notification--noicon {
  grid-template-columns: 1fr;
  grid-template-areas: 'text' 'time'; }

.st_notification + .st_notification {
  border-top: 1px solid var(--st-color-stainless); }

.st_notification--highlight {
  background-color: var(--st-color-cool); }

@media (hover: hover) {
  .st_notification:not(.st_notification--nohover):hover {
    background-color: var(--st-color-blurple-05);
    cursor: pointer; } }

.st_notification__avatar {
  grid-area: avatar; }

.st_notification__text {
  font-size: var(--st-font-size-md);
  line-height: var(--st-line-height-md);
  color: var(--st-color-ink);
  margin: 0;
  padding: 0;
  display: block;
  grid-area: text; }

.st_notification__time {
  font-size: var(--st-font-size-sm);
  line-height: var(--st-line-height-sm);
  color: var(--st-color-asphalt);
  margin: 0;
  padding: 0;
  display: block;
  grid-area: time; }

.st_notification__icon {
  grid-area: icon;
  color: var(--st-color-asphalt);
  align-self: center; }

.st_notifications__empty {
  text-align: center;
  padding: var(--st-spacer-xl) var(--st-spacer-sm); }

.st_notifications__emptytitle {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-xl);
  line-height: var(--st-font-size-lg);
  color: var(--st-color-ink);
  font-weight: var(--st-font-weight-reg);
  margin: 0; }

.st_notifications__emptyimg {
  width: 180px;
  max-width: calc(100% - var(--st-spacer-md));
  margin: 0 auto; }

.st_notifications__emptytext {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-line-height-sm);
  color: var(--st-color-asphalt); }

.st_notifications__empty--lg .st_notifications__emptyimg {
  width: 380px;
  margin-bottom: var(--st-spacer-xl); }

.st_notifications__empty--lg .st_notifications__emptytext {
  font-size: var(--st-font-size-md); }
