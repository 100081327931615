.st_steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.st_step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-sm);
  padding: 0 var(--st-spacer-sm); }

.st_step:first-of-type {
    margin-left: 0; }

.st_step:last-of-type {
    margin-right: 0; }

.st_step + .st_step::before {
    content: '';
    width: var(--st-spacer-md);
    -webkit-transform: translateX(calc(-1 * var(--st-spacer-sm)));
            transform: translateX(calc(-1 * var(--st-spacer-sm)));
    height: 1px;
    background: var(--st-color-stainless); }

.st_step__number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    border: 1px solid var(--st-border-color);
    border-radius: 50%;
    width: var(--st-spacer-mlg);
    height: var(--st-spacer-mlg);
    margin: 0 var(--st-spacer-sm) 0 0; }

.st_step--complete .st_step__number {
    border-color: var(--st-color-blurple-50);
    color: var(--st-color-blurple-50); }

.st_step--active .st_step__number {
    border-color: var(--st-color-blurple-50);
    background-color: var(--st-color-blurple-50);
    color: var(--st-color-white); }
