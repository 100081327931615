.st_brandselect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(--st-spacer-xl) 0;
  font-family: var(--st-font-stack);
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .st_brandselect__grid {
    display: grid;
    grid-gap: var(--st-spacer-md); }
  .st_brandselect__grid--2 {
      grid-template-columns: repeat(2, minmax(50px, 100px)); }
  .st_brandselect__grid--3 {
      grid-template-columns: repeat(3, minmax(50px, 100px)); }
  .st_brandselect__grid--4 {
      grid-template-columns: repeat(4, minmax(50px, 100px)); }
  .st_brandselect__item {
    border: 0;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
    background-color: var(--st-color-ink);
    color: var(--st-color-white); }
  .st_brandselect__item::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 0;
      margin-left: -1px;
      padding-bottom: 100%; }
  .st_brandselect__item--wsj {
      background-color: #222; }
  .st_brandselect__item--marketwatch {
      background-color: #2e2e2f; }
  .st_brandselect__item--barrons {
      background-color: #1a2737; }
  .st_brandselect__item--fnlondon {
      background-color: #f37056; }
  .st_brandselect__item--pen {
      background-color: #1f3771; }
  .st_brandselect__item--mansionglobal {
      background-color: #000; }
  .st_brandselect__item--dowjones {
      background-color: #0c2a31;
      color: #02a3d5; }
  .st_brandselect__item--thetimes {
      background-color: #000; }
  .st_brandselect__logo {
    width: 50px;
    max-width: 50%;
    fill: currentColor; }
