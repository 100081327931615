.st_modal-root {
  z-index: var(--st-zindex-top);
  position: relative; }

.st_modal {
  background: var(--st-color-white);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: var(--st-zindex-top);
  max-height: 100%;
  max-width: 100%;
  overflow: auto; }

.st_modal__title {
    font-family: var(--st-font-stack);
    font-size: var(--st-font-size-xl);
    line-height: var(--st-font-line-height-xl);
    font-weight: var(--st-font-weight-normal);
    color: var(--st-color-ink);
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: block; }

.st_modal__subtitle {
    font-family: var(--st-font-stack);
    font-size: var(--st-font-size-md);
    line-height: var(--st-font-line-height-md);
    font-weight: var(--st-font-weight-normal);
    color: var(--st-color-asphalt);
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: block; }

.st_modal__content {
    margin: 0 auto;
    padding-top: var(--st-spacer-mlg);
    padding-bottom: var(--st-spacer-mlg); }

.st_modal__dismiss {
    color: var(--st-color-asphalt);
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    background: none; }

.st_modal__dismiss--placed {
      position: absolute;
      right: var(--st-spacer-mlg);
      top: var(--st-spacer-mlg); }

.st_modal__dismiss::before {
    content: '';
    width: var(--st-spacer-lg);
    height: var(--st-spacer-lg);
    border-radius: 50%;
    background: var(--st-color-cool);
    opacity: 0;
    display: block;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    margin-top: -7px;
    margin-left: -7px; }

@media (hover: hover) {
    .st_modal__dismiss:hover::before {
      opacity: 1; } }

.st_modal__dismissicon {
    position: relative;
    z-index: 1; }
