/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_headerbar {
  border: solid var(--st-color-stainless);
  border-width: 0 0 1px 0;
  background: var(--st-color-white);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 0 0 var(--st-spacer-mlg);
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  grid-template-areas: 'branding blank links settings notifications user product';
  width: 100%;
  height: var(--st-module-headerbar-bp-1);
  font-size: var(--st-font-stack); }
.st_headerbar--sticky {
    top: 0;
    left: 0;
    position: sticky;
    z-index: var(--st-zindex-major); }
.st_headerbar__item {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
.st_headerbar__item + .st_headerbar__item {
    margin-left: var(--st-spacer-smd);
    /* not using grid-gap bc empty areas also produce spacing */ }
.st_headerbar__item:not(.st_headerbar__product):last-of-type {
    padding-right: var(--st-spacer-mlg); }
.st_headerbar__name {
    font-family: var(--st-font-stack);
    line-height: 1;
    font-size: var(--st-font-size-xl);
    font-weight: normal;
    margin: 0;
    padding: 0;
    grid-area: branding;
    color: var(--st-color-ink);
    text-decoration: none; }
.st_headerbar__settings {
    grid-area: settings; }
.st_headerbar__notifications {
    grid-area: notifications; }
.st_headerbar__user {
    grid-area: user; }
.st_headerbar__links {
    grid-area: links;
    display: inline-grid;
    grid-column-gap: var(--st-spacer-smd);
    grid-auto-flow: column;
    padding-right: var(--st-spacer-sm); }
.st_headerbar__product {
    grid-area: product;
    border-left: 1px solid var(--st-border-color);
    padding-left: var(--st-spacer-smd);
    padding-right: var(--st-spacer-smd); }
.st_headerbar__productlogo {
    display: block;
    height: var(--st-spacer-lg); }
@media (max-width: 47.999rem) {
  .st_headerbar {
    height: var(--st-module-headerbar-bp-2); }
    .st_headerbar__name {
      font-size: var(--st-font-size-lg); } }
