/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_select {
  border: solid var(--st-color-stainless);
  border-width: 1px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  border-radius: var(--st-border-radius);
  background: var(--st-color-white);
  color: var(--st-color-ink);
  padding: var(--st-spacer-sm);
  line-height: var(--st-font-line-height-md);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: var(--st-module-button-height);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgOCAxMCI+CiAgPHBhdGggZmlsbD0iIzYzNkU3MiIgZD0iTTIxLDI1IEwxNywyMSBMMjUsMjEgTDIxLDI1IFogTTIxLDE1IEwyNSwxOSBMMTcsMTkgTDIxLDE1IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNyAtMTUpIi8+Cjwvc3ZnPgo=");
  background-size: 8px 10px;
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;
  cursor: pointer; }
.st_select:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
.st_select--disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: var(--st-color-cool); }
.st_select--invalid {
    border-color: var(--st-color-lava-50);
    background-color: var(--st-color-lava-05); }
