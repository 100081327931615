/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_announcement-bar {
  background-color: var(--st-color-blurple-50);
  color: var(--st-color-white);
  display: grid;
  font-family: var(--st-font-stack); }
.st_announcement-bar__content {
  grid-column-end: 1;
  padding: var(--st-spacer-sm) var(--st-spacer-md);
  /* stylelint-disable-next-line plugin/selector-tag-no-without-class */ }
.st_announcement-bar__content > a:not([class]) {
    color: var(--st-color-white); }
.st_announcement-bar__button {
  background-color: var(--st-color-blurple-70);
  border: 0;
  color: var(--st-color-white);
  cursor: pointer;
  grid-column-start: 1;
  height: 100%;
  justify-self: end;
  padding: 0 var(--st-spacer-sm);
  -webkit-transition: all 400ms;
  transition: all 400ms; }
.st_announcement-bar__button:focus {
    outline: none; }
.st_announcement-bar__button:hover {
    background-color: var(--st-color-blurple-80); }
[data-whatintent='keyboard'] .st_announcement-bar__button:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
