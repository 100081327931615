/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_avatar {
  border: solid var(--st-color-stainless);
  border-width: 1px;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  line-height: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: var(--st-color-gray);
  border-radius: 50%;
  overflow: hidden;
  color: var(--st-color-cool); }
.st_avatar__img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%; }
.st_avatar--smd {
  width: var(--st-spacer-smd);
  height: var(--st-spacer-smd); }
.st_avatar--md {
  width: var(--st-spacer-md);
  height: var(--st-spacer-md); }
.st_avatar--mlg {
  width: var(--st-spacer-mlg);
  height: var(--st-spacer-mlg); }
.st_avatar--lg {
  width: var(--st-spacer-lg);
  height: var(--st-spacer-lg); }
.st_avatar--xl {
  width: var(--st-spacer-xl);
  height: var(--st-spacer-xl); }
.st_avatar--smd,
.st_avatar--md {
  font-size: var(--st-font-size-sm); }
.st_avatar--lg,
.st_avatar--xl {
  font-size: var(--st-font-size-xl); }
