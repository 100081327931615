/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
/*
This is an exception to all other components
Where we are actually allowing selectors without CSS classes
Because it's meant for cases where developer has no control over rendered HTML tags
*/
/* stylelint-disable plugin/selector-tag-no-without-class */
.st_textblock {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md);
  font-family: var(--st-font-stack); }
.st_textblock a {
  color: var(--st-color-blurple-50);
  text-decoration: none;
  font-weight: var(--st-font-weight-med); }
.st_textblock a:hover {
    text-decoration: underline; }
.st_textblock > h5 {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm);
  margin: 0 0 var(--st-spacer-xs); }
.st_textblock > h4 {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md);
  font-weight: var(--st-font-weight-bold);
  margin: 0 0 var(--st-spacer-sm); }
.st_textblock > p {
  margin: 0 0 var(--st-spacer-sm); }
.st_textblock > h3 {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-lg);
  line-height: var(--st-font-line-height-lg);
  margin: 0 0 var(--st-spacer-sm); }
.st_textblock > h2 {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-xl);
  line-height: var(--st-font-line-height-xl);
  margin: 0 0 var(--st-spacer-md); }
.st_textblock > h1 {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-xxl);
  line-height: var(--st-font-line-height-xxl);
  margin: 0 0 var(--st-spacer-md); }
@media (min-width: 48rem) {
  .st_textblock > h3 {
    font-size: var(--st-font-size-lg-bp-2);
    line-height: var(--st-font-line-height-lg-bp-2); }
  .st_textblock > h2 {
    font-size: var(--st-font-size-xl-bp-2);
    line-height: var(--st-font-line-height-xl-bp-2); }
  .st_textblock > h1 {
    font-size: var(--st-font-size-xxl-bp-2);
    line-height: var(--st-font-line-height-xxl-bp-2); } }
