.st_dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  z-index: var(--st-zindex-top);
  -webkit-transition: all 300ms;
  transition: all 300ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-space-around;
      -ms-flex-pack: space-space-around;
          justify-content: space-space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.st_dialog.st_dialog--beforeopen {
  opacity: 0; }

.st_dialog.st_dialog--opening {
  opacity: 1; }

.st_dialog.st_dialog--open {
  opacity: 1; }

.st_dialog.st_dialog--closing,
.st_dialog.st_dialog--closed {
  opacity: 0; }

.st_dialog__content {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  margin: 0 auto;
  max-height: 95vh;
  max-width: 95vw;
  overflow: auto;
  position: relative;
  font-family: var(--st-font-stack); }

.st_dialog.st_dialog--beforeopen .st_dialog__content,
.st_dialog.st_dialog--closing .st_dialog__content {
  -webkit-transform: translate(0, 50px);
          transform: translate(0, 50px); }

.st_dialog.st_dialog--opening .st_dialog__content {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.st_dialog__content.st_dialog__content--sm {
  width: 300px; }

.st_dialog__content.st_dialog__content--md {
  width: 500px; }

.st_dialog__content.st_dialog__content--lg {
  width: 800px; }

.st_dialog__close {
  color: var(--st-color-asphalt);
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  position: absolute;
  top: var(--st-spacer-xs);
  right: var(--st-spacer-smd);
  width: var(--st-spacer-lg);
  height: var(--st-spacer-lg);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0);
  -webkit-transition: all 300ms;
  transition: all 300ms;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

@media (hover: hover) {
    .st_dialog__close:hover {
      background: var(--st-color-cool); } }

.st_dialog__actions {
  display: grid;
  border-top: 1px solid var(--st-color-stainless);
  padding: var(--st-spacer-sm) var(--st-spacer-mlg);
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: var(--st-spacer-sm);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
