.st_sidenav {
  background: var(--st-color-white);
  border-right: 1px solid var(--st-color-border);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%; }

.st_sidenav__section {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.st_sidenav__scrollable {
  overflow-y: auto;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.st_sidenav__sticky {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0; }

.st_sidenav__sticky-top {
    border-bottom: 1px solid var(--st-border-color); }

.st_sidenav__sticky-bottom {
    border-top: 1px solid var(--st-border-color);
    margin-top: auto; }

.st_sidenav__heading {
  color: var(--st-color-asphalt);
  padding: var(--st-spacer-sm) var(--st-spacer-mlg) 0;
  margin: 0 0 var(--st-spacer-xs);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm);
  display: block;
  font-weight: var(--st-font-weight-reg);
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.st_sidenav__item {
  padding: 0 var(--st-spacer-xs);
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.st_sidenav__icon {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.st_sidenav__clickable {
  font-size: var(--st-font-size-md);
  font-weight: var(--st-font-weight-med);
  font-family: var(--st-font-stack);
  line-height: 22px;
  text-decoration: none;
  padding: 3px calc(var(--st-spacer-mlg) - var(--st-spacer-xs));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 0;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: inherit;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.st_sidenav__clickable--wrap {
    text-overflow: visible;
    white-space: normal; }

.st_sidenav__clickable--color-ink {
    color: var(--st-color-ink); }

.st_sidenav__clickable--color-blurple {
    color: var(--st-color-blurple-50); }

.st_sidenav__clickable--active {
    background-color: var(--st-color-blurple-05);
    color: var(--st-color-blurple-50);
    pointer-events: none; }

.st_sidenav__clickable--disabled {
    opacity: 0.5;
    pointer-events: none; }

.st_sidenav__clickable:hover {
    background-color: var(--st-color-blurple-05); }
