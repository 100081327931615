/**
CSS to include once for the entire library
things like CSS custom properties and spacing classes (margin/padding)
*/
@import url(../designtokens/_variables.css);
@import url(./spacing.css);
/**
meant to be reset for body/html tags
not putting directly as body, html
in case Screentone needs to work at the same time as other environments

I'm so sorry, I made a mistake and wrote you should use `st-root` in earlier docs
so we have to support both - and _
*/
/* stylelint-disable-next-line selector-class-pattern */
.st-root,
.st_root {
  padding: 0;
  margin: 0;
  background-color: var(--st-color-cool);
  font-size: 16px; }

/* disable scrolling on body */
.st_noscroll {
  overflow: hidden !important; }
