/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_sidebar {
  --st-module-sidebar-top: var(--st-module-headerbar-bp-1);
  --st-module-sidebar-bottom: 0;
  width: var(--st-module-sidebar-open-width);
  position: fixed;
  background-color: var(--st-color-white);
  -webkit-transition: width 0.3ms, -webkit-transform 0.3ms;
  transition: width 0.3ms, -webkit-transform 0.3ms;
  transition: transform 0.3ms, width 0.3ms;
  transition: transform 0.3ms, width 0.3ms, -webkit-transform 0.3ms;
  top: var(--st-module-sidebar-top);
  bottom: var(--st-module-sidebar-bottom);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
.st_sidebar--left {
    left: 0;
    margin-left: calc(-1 * var(--st-module-sidebar-open-width)) !important; }
.st_sidebar--left--border {
      border-right: 1px solid var(--st-color-stainless); }
.st_sidebar--left.st_sidebar--open, .st_sidebar--left.st_sidebar--opening {
      -webkit-transform: translateX(var(--st-module-sidebar-open-width)) !important;
              transform: translateX(var(--st-module-sidebar-open-width)) !important; }
.st_sidebar--left.st_sidebar--collapsed, .st_sidebar--left.st_sidebar--collapsing {
      -webkit-transform: translateX(var(--st-module-sidebar-open-width)) !important;
              transform: translateX(var(--st-module-sidebar-open-width)) !important; }
.st_sidebar--right {
    right: 0;
    margin-right: calc(-1 * var(--st-module-sidebar-open-width)) !important; }
.st_sidebar--right--border {
      border-left: 1px solid var(--st-color-stainless); }
.st_sidebar--right.st_sidebar--open, .st_sidebar--right.st_sidebar--opening {
      -webkit-transform: translateX(calc(-1 * var(--st-module-sidebar-open-width))) !important;
              transform: translateX(calc(-1 * var(--st-module-sidebar-open-width))) !important; }
.st_sidebar--right.st_sidebar--collapsed, .st_sidebar--right.st_sidebar--collapsing {
      -webkit-transform: translateX(calc(-1 * var(--st-module-sidebar-open-width))) !important;
              transform: translateX(calc(-1 * var(--st-module-sidebar-open-width))) !important; }
.st_sidebar.st_sidebar--open, .st_sidebar.st_sidebar--opening {
    width: var(--st-module-sidebar-open-width) !important; }
.st_sidebar.st_sidebar--collapsed, .st_sidebar.st_sidebar--collapsing {
    width: var(--st-module-sidebar-collapsed-width) !important; }
.st_sidebar.st_sidebar--closed, .st_sidebar.st_sidebar--closing {
    overflow: hidden !important;
    width: var(--st-module-sidebar-open-width) !important; }
.st_sidebar:not(.st_sidebar--permanent).st_sidebar--opening, .st_sidebar:not(.st_sidebar--permanent).st_sidebar--closing, .st_sidebar:not(.st_sidebar--permanent).st_sidebar--collapsing {
  -webkit-transition: width 300ms, margin 300ms, -webkit-transform 300ms !important;
  transition: width 300ms, margin 300ms, -webkit-transform 300ms !important;
  transition: width 300ms, transform 300ms, margin 300ms !important;
  transition: width 300ms, transform 300ms, margin 300ms, -webkit-transform 300ms !important;
  overflow: hidden !important; }
.st_sidebar__page:not(.st_sidebar__page--permanent).st_sidebar__page--opening, .st_sidebar__page:not(.st_sidebar__page--permanent).st_sidebar__page--collapsing, .st_sidebar__page:not(.st_sidebar__page--permanent).st_sidebar__page--closing {
  -webkit-transition: width 300ms, margin 300ms, -webkit-transform 300ms !important;
  transition: width 300ms, margin 300ms, -webkit-transform 300ms !important;
  transition: width 300ms, transform 300ms, margin 300ms !important;
  transition: width 300ms, transform 300ms, margin 300ms, -webkit-transform 300ms !important; }
.st_sidebar__page.st_sidebar__page--push.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--opening, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--collapsing {
  width: 100%; }
.st_sidebar__page.st_sidebar__page--push.st_sidebar__page--left.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--left.st_sidebar__page--opening {
  -webkit-transform: translateX(var(--st-module-sidebar-open-width)) !important;
          transform: translateX(var(--st-module-sidebar-open-width)) !important; }
.st_sidebar__page.st_sidebar__page--push.st_sidebar__page--left.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--left.st_sidebar__page--collapsing {
  -webkit-transform: translateX(var(--st-module-sidebar-collapsed-width)) !important;
          transform: translateX(var(--st-module-sidebar-collapsed-width)) !important; }
.st_sidebar__page.st_sidebar__page--push.st_sidebar__page--right.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--right.st_sidebar__page--opening {
  -webkit-transform: translateX(calc(-1 * var(--st-module-sidebar-open-width))) !important;
          transform: translateX(calc(-1 * var(--st-module-sidebar-open-width))) !important; }
.st_sidebar__page.st_sidebar__page--push.st_sidebar__page--right.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--push.st_sidebar__page--right.st_sidebar__page--collapsing {
  -webkit-transform: translateX(calc(-1 * var(--st-module-sidebar-collapsed-width))) !important;
          transform: translateX(calc(-1 * var(--st-module-sidebar-collapsed-width))) !important; }
.st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--left.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--left.st_sidebar__page--opening {
  margin-left: var(--st-module-sidebar-open-width) !important; }
.st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--left.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--left.st_sidebar__page--collapsing {
  margin-left: var(--st-module-sidebar-collapsed-width) !important; }
.st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--right.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--right.st_sidebar__page--opening {
  margin-right: var(--st-module-sidebar-open-width) !important; }
.st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--right.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--slide.st_sidebar__page--right.st_sidebar__page--collapsing {
  margin-right: var(--st-module-sidebar-collapsed-width) !important; }
.st_sidebar.st_sidebar--overlay.st_sidebar--opening, .st_sidebar.st_sidebar--overlay.st_sidebar--open {
  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1); }
.st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--left.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--left.st_sidebar__page--opening {
  margin-left: var(--st-module-sidebar-open-width) !important; }
.st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--left.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--left.st_sidebar__page--collapsing {
  margin-left: var(--st-module-sidebar-collapsed-width) !important; }
.st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--right.st_sidebar__page--open, .st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--right.st_sidebar__page--opening {
  margin-right: var(--st-module-sidebar-open-width) !important; }
.st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--right.st_sidebar__page--collapsed, .st_sidebar__page.st_sidebar__page--permanent.st_sidebar__page--right.st_sidebar__page--collapsing {
  margin-right: var(--st-module-sidebar-collapsed-width) !important; }
.st_sidebar__section {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
.st_sidebar__section--scrollable {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow-y: auto; }
.st_sidebar__section--sticky {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; }
.st_sidebar__section--sticky-top.st_sidebar__section--border {
      border-bottom: 1px solid var(--st-border-color); }
.st_sidebar__section--sticky-bottom {
      margin-top: auto; }
.st_sidebar__section--sticky-bottom.st_sidebar__section--border {
      border-top: 1px solid var(--st-border-color); }
.st_sidebar__toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
.st_sidebar__toggle--left {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
.st_sidebar__toggle--right {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
.st_sidebar__togglebtn {
  border: 0;
  color: var(--st-color-gray);
  border-radius: var(--st-border-radius);
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0; }
.st_sidebar__togglebtn:hover {
    background-color: var(--st-color-cool); }
.st_sidebar__togglebtn:focus {
    outline: none; }
@media (max-width: 47.999rem) {
  .st_sidebar {
    --st-module-sidebar-top: var(--st-module-headerbar-bp-2); } }
/*

method of disabling focus ring if we are clicking
powered by what-input
replace with :focus-visible once that is adopted by more browsers

*/
[data-whatintent='keyboard'] .st_sidebar__togglebtn:focus {
  -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
          box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
