.st_tooltip {
  position: relative;
  display: inline-block; }

.st_tooltip_trigger {
  cursor: pointer; }

.st_tooltip_content {
  background-color: var(--st-color-black);
  color: var(--st-color-cool);
  border-radius: var(--st-border-radius);
  padding: var(--st-spacer-sm);
  position: absolute;
  display: none;
  z-index: var(--st-zindex-major);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm); }

.st_tooltip_content--none {
    white-space: nowrap; }

.st_tooltip_content--md {
    width: calc(var(--st-spacer-md) * 4); }

.st_tooltip_content--mlg {
    width: calc(var(--st-spacer-mlg) * 4); }

.st_tooltip_content--lg {
    width: calc(var(--st-spacer-lg) * 4); }

.st_tooltip_content--xl {
    width: calc(var(--st-spacer-xl) * 4); }

.st_tooltip_content--top {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    bottom: calc(100% + var(--st-spacer-xs)); }

.st_tooltip_content--right {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    left: calc(100% + var(--st-spacer-sm)); }

.st_tooltip_content--bottom {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    top: calc(100% + var(--st-spacer-xs)); }

.st_tooltip_content--left {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    right: calc(100% + var(--st-spacer-sm)); }

.st_tooltip:hover .st_tooltip_content {
  display: block; }
