.st_toast {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--st-color-ink);
  border-radius: var(--st-border-radius);
  bottom: 0;
  color: var(--st-color-stainless);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: var(--st-zindex-top); }
  .st_toast--left {
    -webkit-animation: slide 0.6s ease-out;
            animation: slide 0.6s ease-out;
    left: 0; }
  .st_toast--center {
    -webkit-animation: slide-center 0.6s ease-out;
            animation: slide-center 0.6s ease-out;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .st_toast--right {
    -webkit-animation: slide 0.6s ease-out;
            animation: slide 0.6s ease-out;
    right: 0; }
  .st_toast__content {
    font-family: var(--st-font-stack);
    font-size: var(--st-font-size-sm);
    padding: var(--st-spacer-sm) var(--st-spacer-md); }
  .st_toast__dismiss {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: transparent;
    border: 0;
    color: var(--st-color-stainless);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: var(--st-font-stack);
    font-size: var(--st-font-size-md);
    justify-self: center;
    margin-right: var(--st-spacer-md);
    padding: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms; }
  .st_toast__dismiss:focus {
      outline: none; }
  @-webkit-keyframes slide {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
  @keyframes slide {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
  @-webkit-keyframes slide-center {
  0% {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); } }
  @keyframes slide-center {
  0% {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); } }
