.st_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  z-index: var(--st-zindex-top);
  -webkit-transition: all 300ms;
  transition: all 300ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-space-around;
      -ms-flex-pack: space-space-around;
          justify-content: space-space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer; }

.st_overlay.st_overlay--beforeopen {
  opacity: 0; }

.st_overlay.st_overlay--opening {
  opacity: 1; }

.st_overlay.st_overlay--open {
  opacity: 1; }

.st_overlay.st_overlay--closing,
.st_overlay.st_overlay--closed {
  opacity: 0; }

.st_overlay__content {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  margin: 0 auto;
  position: relative;
  cursor: auto;
  cursor: initial; }

.st_overlay.st_overlay--beforeopen .st_overlay__content,
.st_overlay.st_overlay--closing .st_overlay__content {
  -webkit-transform: translate(0, 50px);
          transform: translate(0, 50px); }

.st_overlay.st_overlay--opening .st_overlay__content {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

/*

.st_overlay__dismiss {
  color: var(--st-color-asphalt);
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  appearance: none;
  border: 0;
  background: none;

  &--placed {
    position: absolute;
    right: var(--st-spacer-mlg);
    top: var(--st-spacer-mlg);
  }
}

.st_overlay__dismiss::before {
  content: '';
  width: var(--st-spacer-lg);
  height: var(--st-spacer-lg);
  border-radius: 50%;
  background: var(--st-color-cool);
  opacity: 0;
  display: block;
  position: absolute;
  transition: all 300ms;
  margin-top: -7px;
  margin-left: -7px;
}

@media (hover: hover) {
  .st_overlay__dismiss:hover::before {
    opacity: 1;
  }
}

.st_overlay__dismissicon {
  position: relative;
  z-index: 1;
}
*/
