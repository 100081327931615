/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_usernav {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  color: var(--st-color-ink);
  display: flex;
  background: var(--st-color-white);
  flex-direction: column;
  min-width: 300px;
  max-width: 100vw;
  /* stylelint-disable-next-line plugin/selector-tag-no-without-class */
  /* stylelint-disable-next-line plugin/selector-tag-no-without-class */ }
  .st_usernav__trigger {
    border: 0;
    background: none;
    margin: 0 calc(-1 * var(--st-spacer-xs));
    appearance: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: var(--st-border-radius);
    padding: 0 var(--st-spacer-xs);
    background: transparent;
    transition: all 300ms; }
    .st_usernav__trigger:hover {
      background-color: var(--st-color-blurple-05); }
    .st_usernav__trigger:focus {
      outline: none; }
  .st_usernav__item > a {
    color: inherit;
    text-decoration: inherit; }
  .st_usernav__item + .st_usernav__item {
    border-top: 1px solid var(--st-border-color); }
  .st_usernav__userbox {
    display: flex;
    align-items: center; }
  .st_usernav__avatar {
    margin-right: var(--st-spacer-sm); }
  .st_usernav__footer {
    font-size: var(--st-font-size-sm);
    background-color: var(--st-color-cool); }
  .st_usernav__credit > a:not([class]),
  .st_usernav__version > a:not([class]) {
    color: inherit;
    text-decoration: underline; }
  .st_usernav__item + .st_usernav__footer {
    border-top: 1px solid var(--st-border-color); }

/*

method of disabling focus ring if we are clicking
powered by what-input
replace with :focus-visible once that is adopted by more browsers

*/
[data-whatintent='keyboard'] .st_usernav__trigger:focus {
  border-color: var(--st-color-blurple-50);
  outline: none;
  box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
