@charset "UTF-8";
.st_list {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  color: var(--st-color-ink);
  display: block;
  counter-reset: numbers;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_listitem {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_listitem::before {
    margin-right: var(--st-spacer-xs);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    min-width: 1em; }
.st_listitem--bullet::before {
    -ms-flex-item-align: start;
        align-self: flex-start;
    content: '•'; }
.st_listitem--number::before {
    -ms-flex-item-align: start;
        align-self: flex-start;
    counter-increment: numbers;
    content: "" counter(numbers) ". "; }
.st_listitem__avatar {
    margin-right: var(--st-spacer-sm);
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-item-align: start;
        align-self: flex-start; }
.st_listitem__icon {
    margin-right: var(--st-spacer-xs);
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-item-align: center;
        align-self: center; }
