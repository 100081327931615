/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_box {
  border: solid var(--st-color-stainless);
  border-width: 1px;
  border-radius: var(--st-border-radius);
  background: var(--st-color-white);
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_box__title {
    border: solid var(--st-color-stainless);
    border-width: 0 0 1px;
    font-family: var(--st-font-stack);
    font-weight: var(--st-font-weight-reg);
    font-size: var(--st-font-size-sm);
    line-height: var(--st-font-line-height-sm);
    display: block;
    font-weight: var(--st-font-weight-bold);
    text-transform: uppercase;
    color: var(--st-color-asphalt);
    /* stylelint-disable-next-line plugin/selector-tag-no-without-class */ }
.st_box__title > a:not([class]) {
      color: var(--st-color-asphalt);
      text-decoration: none; }
.st_box--fullwidth {
    border-left: 0;
    border-right: 0;
    border-radius: 0; }
.st_box--arrow {
    position: relative;
    min-height: 30px; }
.st_box--arrow::before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid; }
.st_box--arrow::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid; }
.st_box--arrow-top::before, .st_box--arrow-top::after {
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
.st_box--arrow-top::before {
    border-width: 0 9px 9px;
    border-color: transparent transparent var(--st-color-stainless) transparent; }
.st_box--arrow-top::after {
    margin-top: 1px;
    margin-left: 0;
    border-width: 0 8px 8px;
    border-color: transparent transparent var(--st-color-white) transparent; }
.st_box--arrow-top-left::before, .st_box--arrow-top-left::after {
    top: 0;
    left: var(--st-spacer-smd);
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
.st_box--arrow-top-left::before {
    border-width: 0 9px 9px;
    border-color: transparent transparent var(--st-color-stainless) transparent; }
.st_box--arrow-top-left::after {
    margin-top: 1px;
    margin-left: 0;
    border-width: 0 8px 8px;
    border-color: transparent transparent var(--st-color-white) transparent; }
.st_box--arrow-top-right::before, .st_box--arrow-top-right::after {
    top: 0;
    right: 0;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
.st_box--arrow-top-right::before {
    border-width: 0 8px 9px;
    border-color: transparent transparent var(--st-color-stainless) transparent; }
.st_box--arrow-top-right::after {
    margin-top: 1px;
    margin-left: 0;
    border-width: 0 8px 8px;
    border-color: transparent transparent var(--st-color-white) transparent; }
.st_box--arrow-right::before, .st_box--arrow-right::after {
    right: 0;
    top: 50%;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
.st_box--arrow-right::before {
    border-width: 9px 0 9px 9px;
    border-color: transparent transparent transparent var(--st-color-stainless); }
.st_box--arrow-right::after {
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent var(--st-color-white);
    margin-top: 0;
    margin-left: 1px; }
.st_box--arrow-bottom::before, .st_box--arrow-bottom::after {
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%); }
.st_box--arrow-bottom::before {
    border-width: 9px 9px 0;
    border-color: var(--st-color-stainless) transparent transparent transparent; }
.st_box--arrow-bottom::after {
    margin-top: 0;
    margin-left: 0;
    border-width: 8px 8px 0;
    border-color: var(--st-color-white) transparent transparent transparent; }
.st_box--arrow-left::before, .st_box--arrow-left::after {
    left: 0;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
.st_box--arrow-left::before {
    border-width: 9px 9px 9px 0;
    border-color: transparent var(--st-color-stainless) transparent transparent; }
.st_box--arrow-left::after {
    border-width: 8px 8px 8px 0;
    border-color: transparent var(--st-color-white) transparent transparent;
    margin-top: 0;
    margin-left: 1px; }
.st_box__content {
    /* content div when there is a title */ }
.st_box--attached-top {
    border-bottom: 0; }
.st_box--attached-bottom {
    border-top: 0; }
.st_numberbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
.st_numberbox__counter {
    border: solid var(--st-color-stainless);
    border-width: 0 1px 0 0;
    font-family: var(--st-font-stack);
    font-size: var(--st-font-size-xl);
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: var(--st-spacer-smd);
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
.st_numberbox__autocount::before {
    counter-increment: var(--st-numberbox-counter);
    content: counter(var(--st-numberbox-counter)); }
.st_numberbox__content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
