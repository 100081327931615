/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_icon {
  fill: currentColor;
  width: var(--st-spacer-md);
  height: var(--st-spacer-md);
  display: inline-block;
  vertical-align: baseline;
  line-height: 1em; }
.st_icon--color-ink {
    color: var(--st-color-ink); }
.st_icon--color-blurple {
    color: var(--st-color-blurple-50); }
.st_icon--color-asphalt {
    color: var(--st-color-asphalt); }
.st_icon--color-lava {
    color: var(--st-color-lava-50); }
.st_icon--color-emerald {
    color: var(--st-color-emerald-50); }
.st_icon--color-white {
    color: var(--st-color-white); }
.st_icon--sm {
  width: var(--st-spacer-sm);
  height: var(--st-spacer-sm); }
.st_icon--smd {
  width: var(--st-spacer-smd);
  height: var(--st-spacer-smd); }
.st_icon--md {
  width: var(--st-spacer-md);
  height: var(--st-spacer-md); }
.st_icon--mlg {
  width: var(--st-spacer-mlg);
  height: var(--st-spacer-mlg); }
.st_icon--lg {
  width: var(--st-spacer-lg);
  height: var(--st-spacer-lg); }
