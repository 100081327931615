/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_toggle {
  border: solid var(--st-color-stainless);
  border-width: 1px;
  border-radius: var(--st-border-radius);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background: var(--st-color-white);
  padding: var(--st-spacer-sm) var(--st-spacer-xs);
  max-width: 100%;
  overflow-x: auto; }
.st_toggleItem__icon {
  margin-right: var(--spacer-sm); }
