/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */

:root {
 --st-border-radius: 2px;
 --st-border-color: #dfe6e9;
 --st-border-width: 1px;
 --st-color-white: #ffffff;
 --st-color-cool: #f5f7f8;
 --st-color-stainless: #dfe6e9;
 --st-color-stainless-opac-20: rgba(223, 230, 233, 0.2);
 --st-color-gray: #b2bec3;
 --st-color-asphalt: #636e72;
 --st-color-ink: #2d3436;
 --st-color-black: #000000;
 --st-color-brand-dowjones: #02a3d5;
 --st-color-rose-10: #ffdde9;
 --st-color-rose-20: #ffbbd3;
 --st-color-rose-30: #ff99be;
 --st-color-rose-40: #ff77a8;
 --st-color-rose-50: #ff5592;
 --st-color-rose-60: #cc4475;
 --st-color-rose-70: #993358;
 --st-color-rose-80: #66223a;
 --st-color-rose-90: #33111d;
 --st-color-rose-05: #ffeef4;
 --st-color-lava-10: #fad9d4;
 --st-color-lava-20: #f4b3a8;
 --st-color-lava-30: #ef8e7d;
 --st-color-lava-40: #e96851;
 --st-color-lava-50: #e44226;
 --st-color-lava-60: #b6351e;
 --st-color-lava-70: #892817;
 --st-color-lava-80: #5b1a0f;
 --st-color-lava-90: #2e0d08;
 --st-color-lava-05: #fcece9;
 --st-color-tangerine-10: #fde9da;
 --st-color-tangerine-20: #fbd3b6;
 --st-color-tangerine-30: #fabc91;
 --st-color-tangerine-40: #f8a66c;
 --st-color-tangerine-50: #f69047;
 --st-color-tangerine-60: #c57339;
 --st-color-tangerine-70: #764522;
 --st-color-tangerine-80: #623a1d;
 --st-color-tangerine-90: #311d0e;
 --st-color-tangerine-05: #fef3ec;
 --st-color-sunshine-10: #fef3d9;
 --st-color-sunshine-20: #fce7b4;
 --st-color-sunshine-30: #fbdc8e;
 --st-color-sunshine-40: #f9d069;
 --st-color-sunshine-50: #f8c443;
 --st-color-sunshine-60: #c69d36;
 --st-color-sunshine-70: #957628;
 --st-color-sunshine-80: #634e1b;
 --st-color-sunshine-90: #32270d;
 --st-color-sunshine-05: #fef9ec;
 --st-color-emerald-10: #dcf5da;
 --st-color-emerald-20: #b9ecb4;
 --st-color-emerald-30: #97e28f;
 --st-color-emerald-40: #74d969;
 --st-color-emerald-50: #51cf44;
 --st-color-emerald-60: #41a636;
 --st-color-emerald-70: #317c29;
 --st-color-emerald-80: #20531b;
 --st-color-emerald-90: #10290e;
 --st-color-emerald-05: #edfaec;
 --st-color-ice-10: #d9f6ff;
 --st-color-ice-20: #b3ecff;
 --st-color-ice-30: #8de3ff;
 --st-color-ice-40: #66daff;
 --st-color-ice-50: #40d0ff;
 --st-color-ice-60: #33a7cc;
 --st-color-ice-70: #277d99;
 --st-color-ice-80: #1a5366;
 --st-color-ice-90: #0d2a33;
 --st-color-ice-05: #ebfaff;
 --st-color-blurple-10: #ddddf7;
 --st-color-blurple-20: #bcbbf0;
 --st-color-blurple-30: #9a99e8;
 --st-color-blurple-40: #7977e1;
 --st-color-blurple-50: #5755d9;
 --st-color-blurple-60: #4644ae;
 --st-color-blurple-70: #343382;
 --st-color-blurple-80: #232257;
 --st-color-blurple-90: #11112b;
 --st-color-blurple-05: #eeeefb;
 --st-color-blurple-opac-50: rgba(87, 85, 217, 0.5);
 --st-font-stack: 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
 --st-font-size-sm: 0.8125rem;
 --st-font-size-md: 1rem;
 --st-font-size-lg: 1.125rem;
 --st-font-size-xl: 1.3125rem;
 --st-font-size-xxl: 1.75rem;
 --st-font-size-lg-bp-2: 1.3125rem;
 --st-font-size-xl-bp-2: 1.75rem;
 --st-font-size-xxl-bp-2: 2.375rem;
 --st-font-line-height-sm: 1rem;
 --st-font-line-height-md: 1.5rem;
 --st-font-line-height-lg: 1.625rem;
 --st-font-line-height-xl: 1.875rem;
 --st-font-line-height-xxl: 2.5625rem;
 --st-font-line-height-lg-bp-2: 1.875rem;
 --st-font-line-height-xl-bp-2: 2.5625rem;
 --st-font-line-height-xxl-bp-2: 3.4375rem;
 --st-font-weight-reg: 400;
 --st-font-weight-med: 500;
 --st-font-weight-bold: 700;
 --st-font-weight-black: 900;
 --st-grid-breakpoint-xs-max: 47.999rem;
 --st-grid-breakpoint-sm-min: 48rem;
 --st-grid-breakpoint-sm-max: 63.999rem;
 --st-grid-breakpoint-md-min: 64rem;
 --st-grid-breakpoint-md-max: 74.999rem;
 --st-grid-breakpoint-lg-min: 75rem;
 --st-grid-gutter-width: 1rem;
 --st-grid-gutter-half: 0.5rem;
 --st-grid-gutter-compensation: -0.5rem;
 --st-grid-container-sm: 49rem;
 --st-grid-container-md: 65rem;
 --st-grid-container-lg: 76rem;
 --st-grid-margin-outer: 0rem;
 --st-grid-page-fullwidth: 75rem;
 --st-module-button-height: 40px;
 --st-module-sidebar-open-width: 260px;
 --st-module-sidebar-collapsed-width: 32px;
 --st-module-headerbar-bp-1: 60px;
 --st-module-headerbar-bp-2: 48px;
 --st-spacer-xs: 4px;
 --st-spacer-sm: 8px;
 --st-spacer-smd: 12px;
 --st-spacer-md: 16px;
 --st-spacer-mlg: 24px;
 --st-spacer-lg: 32px;
 --st-spacer-xl: 48px;
 --st-zindex-top: 100000000000000000;
 --st-zindex-major: 100;
 --st-zindex-mid: 2;
 --st-zindex-minor: 1;
}
