/*
  CSS classes for padding and margin
*/
/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_p-hor-none {
  padding-left: 0;
  padding-right: 0; }

.st_p-left-none {
  padding-left: 0; }

.st_p-right-none {
  padding-right: 0; }

.st_p-ver-none {
  padding-top: 0;
  padding-bottom: 0; }

.st_p-bot-none {
  padding-bottom: 0; }

.st_p-top-none {
  padding-top: 0; }

.st_p-none {
  padding: 0; }

.st_p-hor-xs {
  padding-left: 4px;
  padding-right: 4px; }

.st_p-left-xs {
  padding-left: 4px; }

.st_p-right-xs {
  padding-right: 4px; }

.st_p-ver-xs {
  padding-top: 4px;
  padding-bottom: 4px; }

.st_p-bot-xs {
  padding-bottom: 4px; }

.st_p-top-xs {
  padding-top: 4px; }

.st_p-xs {
  padding: 4px; }

.st_p-hor-sm {
  padding-left: 8px;
  padding-right: 8px; }

.st_p-left-sm {
  padding-left: 8px; }

.st_p-right-sm {
  padding-right: 8px; }

.st_p-ver-sm {
  padding-top: 8px;
  padding-bottom: 8px; }

.st_p-bot-sm {
  padding-bottom: 8px; }

.st_p-top-sm {
  padding-top: 8px; }

.st_p-sm {
  padding: 8px; }

.st_p-hor-smd {
  padding-left: 12px;
  padding-right: 12px; }

.st_p-left-smd {
  padding-left: 12px; }

.st_p-right-smd {
  padding-right: 12px; }

.st_p-ver-smd {
  padding-top: 12px;
  padding-bottom: 12px; }

.st_p-bot-smd {
  padding-bottom: 12px; }

.st_p-top-smd {
  padding-top: 12px; }

.st_p-smd {
  padding: 12px; }

.st_p-hor-md {
  padding-left: 16px;
  padding-right: 16px; }

.st_p-left-md {
  padding-left: 16px; }

.st_p-right-md {
  padding-right: 16px; }

.st_p-ver-md {
  padding-top: 16px;
  padding-bottom: 16px; }

.st_p-bot-md {
  padding-bottom: 16px; }

.st_p-top-md {
  padding-top: 16px; }

.st_p-md {
  padding: 16px; }

.st_p-hor-mlg {
  padding-left: 24px;
  padding-right: 24px; }

.st_p-left-mlg {
  padding-left: 24px; }

.st_p-right-mlg {
  padding-right: 24px; }

.st_p-ver-mlg {
  padding-top: 24px;
  padding-bottom: 24px; }

.st_p-bot-mlg {
  padding-bottom: 24px; }

.st_p-top-mlg {
  padding-top: 24px; }

.st_p-mlg {
  padding: 24px; }

.st_p-hor-lg {
  padding-left: 32px;
  padding-right: 32px; }

.st_p-left-lg {
  padding-left: 32px; }

.st_p-right-lg {
  padding-right: 32px; }

.st_p-ver-lg {
  padding-top: 32px;
  padding-bottom: 32px; }

.st_p-bot-lg {
  padding-bottom: 32px; }

.st_p-top-lg {
  padding-top: 32px; }

.st_p-lg {
  padding: 32px; }

.st_p-hor-xl {
  padding-left: 48px;
  padding-right: 48px; }

.st_p-left-xl {
  padding-left: 48px; }

.st_p-right-xl {
  padding-right: 48px; }

.st_p-ver-xl {
  padding-top: 48px;
  padding-bottom: 48px; }

.st_p-bot-xl {
  padding-bottom: 48px; }

.st_p-top-xl {
  padding-top: 48px; }

.st_p-xl {
  padding: 48px; }

.st_m-hor-none {
  margin-left: 0;
  margin-right: 0; }

.st_m-left-none {
  margin-left: 0; }

.st_m-right-none {
  margin-right: 0; }

.st_m-ver-none {
  margin-top: 0;
  margin-bottom: 0; }

.st_m-bot-none {
  margin-bottom: 0; }

.st_m-top-none {
  margin-top: 0; }

.st_m-none {
  margin: 0; }

.st_m-hor-xs {
  margin-left: 4px;
  margin-right: 4px; }

.st_m-left-xs {
  margin-left: 4px; }

.st_m-right-xs {
  margin-right: 4px; }

.st_m-ver-xs {
  margin-top: 4px;
  margin-bottom: 4px; }

.st_m-bot-xs {
  margin-bottom: 4px; }

.st_m-top-xs {
  margin-top: 4px; }

.st_m-xs {
  margin: 4px; }

.st_m-hor-sm {
  margin-left: 8px;
  margin-right: 8px; }

.st_m-left-sm {
  margin-left: 8px; }

.st_m-right-sm {
  margin-right: 8px; }

.st_m-ver-sm {
  margin-top: 8px;
  margin-bottom: 8px; }

.st_m-bot-sm {
  margin-bottom: 8px; }

.st_m-top-sm {
  margin-top: 8px; }

.st_m-sm {
  margin: 8px; }

.st_m-hor-smd {
  margin-left: 12px;
  margin-right: 12px; }

.st_m-left-smd {
  margin-left: 12px; }

.st_m-right-smd {
  margin-right: 12px; }

.st_m-ver-smd {
  margin-top: 12px;
  margin-bottom: 12px; }

.st_m-bot-smd {
  margin-bottom: 12px; }

.st_m-top-smd {
  margin-top: 12px; }

.st_m-smd {
  margin: 12px; }

.st_m-hor-md {
  margin-left: 16px;
  margin-right: 16px; }

.st_m-left-md {
  margin-left: 16px; }

.st_m-right-md {
  margin-right: 16px; }

.st_m-ver-md {
  margin-top: 16px;
  margin-bottom: 16px; }

.st_m-bot-md {
  margin-bottom: 16px; }

.st_m-top-md {
  margin-top: 16px; }

.st_m-md {
  margin: 16px; }

.st_m-hor-mlg {
  margin-left: 24px;
  margin-right: 24px; }

.st_m-left-mlg {
  margin-left: 24px; }

.st_m-right-mlg {
  margin-right: 24px; }

.st_m-ver-mlg {
  margin-top: 24px;
  margin-bottom: 24px; }

.st_m-bot-mlg {
  margin-bottom: 24px; }

.st_m-top-mlg {
  margin-top: 24px; }

.st_m-mlg {
  margin: 24px; }

.st_m-hor-lg {
  margin-left: 32px;
  margin-right: 32px; }

.st_m-left-lg {
  margin-left: 32px; }

.st_m-right-lg {
  margin-right: 32px; }

.st_m-ver-lg {
  margin-top: 32px;
  margin-bottom: 32px; }

.st_m-bot-lg {
  margin-bottom: 32px; }

.st_m-top-lg {
  margin-top: 32px; }

.st_m-lg {
  margin: 32px; }

.st_m-hor-xl {
  margin-left: 48px;
  margin-right: 48px; }

.st_m-left-xl {
  margin-left: 48px; }

.st_m-right-xl {
  margin-right: 48px; }

.st_m-ver-xl {
  margin-top: 48px;
  margin-bottom: 48px; }

.st_m-bot-xl {
  margin-bottom: 48px; }

.st_m-top-xl {
  margin-top: 48px; }

.st_m-xl {
  margin: 48px; }
