/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  font-weight: var(--st-font-weight-med);
  border-radius: var(--st-border-radius);
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: nowrap; }
.st_btn--disabled, .st_btn:disabled {
    opacity: 0.5;
    pointer-events: none; }
.st_btn--focus, .st_btn:focus {
    outline: none; }
.st_btn__icon {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
/*

method of disabling focus ring if we are clicking
powered by what-input
replace with :focus-visible once that is adopted by more browsers

*/
[data-whatintent='keyboard'] .st_btn:focus,
[data-whatintent='keyboard'] .st_btn--focus {
  border-color: var(--st-color-blurple-50);
  outline: none;
  -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
          box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
.st_btn.st_btn--primary {
  background: var(--st-color-blurple-50);
  color: var(--st-color-white);
  height: var(--st-module-button-height);
  padding: 0 var(--st-spacer-lg);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
.st_btn.st_btn--primary:hover {
    background: var(--st-color-blurple-60); }
.st_btn.st_btn--primary:active, .st_btn.st_btn--primary.st_btn--active {
    background: var(--st-color-blurple-70); }
.st_btn.st_btn--secondary {
  background: var(--st-color-white);
  border: 1px solid var(--st-color-blurple-50);
  color: var(--st-color-blurple-50);
  height: var(--st-module-button-height);
  padding: 0 var(--st-spacer-lg);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
.st_btn.st_btn--secondary:hover {
    background: var(--st-color-blurple-05); }
.st_btn.st_btn--secondary:active, .st_btn.st_btn--secondary.st_btn--active {
    background: var(--st-color-blurple-10); }
.st_btn.st_btn--tertiary {
  height: var(--st-spacer-mlg);
  color: var(--st-color-blurple-50);
  padding: 0 var(--st-spacer-xs);
  background: transparent;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left; }
.st_btn.st_btn--tertiary:hover {
    background: var(--st-color-blurple-05); }
.st_btn.st_btn--tertiary:active, .st_btn.st_btn--tertiary.st_btn--active {
    background: var(--st-color-blurple-10); }
.st_btn.st_btn--tertiary--white {
    color: var(--st-color-white); }
.st_btn.st_btn--tertiary--white:hover {
      background: rgba(255, 255, 255, 0.2); }
.st_btn.st_btn--tertiary--white:active, .st_btn.st_btn--tertiary--white.st_btn--active {
      background: rgba(255, 255, 255, 0.3); }
.st_btn.st_btn--tertiary--gray {
    color: var(--st-color-ink); }
.st_btn.st_btn--tertiary--gray:hover {
      background: var(--st-color-stainless); }
.st_btn.st_btn--tertiary--gray:active, .st_btn.st_btn--tertiary--gray.st_btn--active {
      background: var(--st-color-cool); }
.st_btn.st_btn--tertiary--asphalt {
    color: var(--st-color-asphalt); }
.st_btn.st_btn--tertiary--asphalt:hover {
      background: var(--st-color-cool); }
.st_btn.st_btn--tertiary--asphalt:active, .st_btn.st_btn--tertiary--asphalt.st_btn--active {
      background: var(--st-color-cool); }
.st_btn.st_btn--tertiary--lava {
    color: var(--st-color-lava-50); }
.st_btn.st_btn--tertiary--lava:hover {
      background: var(--st-color-lava-05); }
.st_btn.st_btn--tertiary--lava:active, .st_btn.st_btn--tertiary--lava.st_btn--active {
      background: var(--st-color-lava-10); }
.st_btn.st_btn--tertiary--emerald {
    color: var(--st-color-emerald-50); }
.st_btn.st_btn--tertiary--emerald:hover {
      background: var(--st-color-emerald-05); }
.st_btn.st_btn--tertiary--emerald:active, .st_btn.st_btn--tertiary--emerald.st_btn--active {
      background: var(--st-color-emerald-10); }
.st_btn.st_btn--primary.st_btn--size-sm,
.st_btn.st_btn--secondary.st_btn--size-sm {
  padding: 0 var(--st-spacer-sm);
  height: var(--st-spacer-mlg); }
.st_btn.st_btn--justified-left {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
.st_btn.st_btn--justified-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
.st_btn.st_btn--justified-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
.st_btn.st_btn--full-width {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }
.st_btn--fab {
  position: absolute;
  width: calc(var(--st-spacer-xl) + var(--st-spacer-sm));
  height: calc(var(--st-spacer-xl) + var(--st-spacer-sm));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0 2px var(--st-spacer-md) var(--st-color-blurple-opac-50);
          box-shadow: 0 2px var(--st-spacer-md) var(--st-color-blurple-opac-50);
  border-radius: 50%;
  color: var(--st-color-white);
  background: var(--st-color-blurple-50);
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: -1; }
.st_btn--fab.st_btn--fab-inverted {
    color: var(--st-color-blurple-50);
    background: var(--st-color-white); }
.st_btn--fab:hover {
    background: var(--st-color-blurple-60);
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1); }
.st_btn--fab-group {
  position: absolute;
  bottom: calc(var(--st-spacer-xl) + var(--st-spacer-sm));
  text-align: center;
  padding: var(--st-spacer-sm) 0;
  visibility: hidden;
  opacity: 0;
  z-index: inherit; }
.st_btn--fab-group .st_btn--fab {
    margin: var(--st-spacer-sm) 0;
    position: relative;
    z-index: inherit;
    width: var(--st-spacer-xl);
    height: var(--st-spacer-xl);
    font-size: var(--st-font-size-xs); }
.st_btn--fab-group .st_btn--fab:hover {
      visibility: visible;
      background: inherit;
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
      color: var(--st-color-blurple-50); }
.st_btn--fab-container {
  position: fixed;
  bottom: var(--st-spacer-lg);
  right: var(--st-spacer-lg);
  width: calc(var(--st-spacer-xl) + var(--st-spacer-sm));
  height: calc(var(--st-spacer-xl) + var(--st-spacer-sm)); }
.st_btn--fab-container:hover .st_btn--fab-group {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
/*
to be handled in separate component
// Button Group
.btn-group {
  .btn {
    margin: 0 -1px 0 0;
    border-radius: 0;
    padding: 0 var(--st-spacer-md);

    &:first-of-type {
      border-radius: var(--st-border-radius) 0 0 var(--st-border-radius);
    }

    &:last-of-type {
      border-radius: 0 var(--st-border-radius) var(--st-border-radius) 0;
    }

    &.selected {
      background: var(--st-color-blurple-50);
      color: var(--st-color-white);
    }
  }
}
*/
