/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_dropdown {
  display: inline-block; }
.st_dropdown--open {
    position: relative; }
.st_dropdown__content {
    position: absolute;
    z-index: var(--st-zindex-minor); }
.st_dropdown__content--left {
      top: calc(100% + var(--st-spacer-xs));
      left: calc(-1 * var(--st-spacer-xs)); }
.st_dropdown__content--left-flush {
      top: calc(100% + var(--st-spacer-xs));
      left: 0; }
.st_dropdown__content--right {
      top: calc(100% + var(--st-spacer-xs));
      right: calc(-1 * var(--st-spacer-xs)); }
.st_dropdown__content--right-flush {
      top: calc(100% + var(--st-spacer-xs));
      right: 0; }
.st_dropdown__content--none-horizontal {
      top: calc(100% + var(--st-spacer-xs)); }
/* stylelint-disable-next-line selector-class-pattern */
.mycustomarrowclass {
  -webkit-transform: translateX(calc(-50% + 12px));
          transform: translateX(calc(-50% + 12px)); }
