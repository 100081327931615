.st_alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: var(--st-spacer-md);
  border: 1px solid;
  border-radius: 2px;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md); }
  .st_alert__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 0 var(--st-spacer-md) 0 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .st_alert__icon {
    margin: 3px var(--st-spacer-sm) 0 0;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .st_alert__action {
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin-left: auto;
    padding-left: var(--st-spaer-sm);
    color: currentColor;
    text-decoration: underline;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .st_alert--floating {
    position: fixed;
    top: var(--st-spacer-xl);
    left: var(--st-spacer-xl);
    right: var(--st-spacer-xl);
    z-index: var(--st-zindex-major); }
  .st_alert--fullwidth {
    border-width: 1px 0;
    border-radius: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .st_alert__dismiss {
    opacity: 0.8;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    cursor: pointer;
    background: none;
    padding: 0;
    margin: 3px 0 0 auto;
    border: 0;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    color: currentColor; }
  .st_alert__dismiss:hover {
      opacity: 1; }
  .st_alert--primary {
    background: var(--st-color-blurple-10);
    border-color: var(--st-color-blurple-70);
    color: var(--st-color-blurple-70); }
  .st_alert--gray {
    background: var(--st-color-stainless);
    border-color: var(--st-color-ink);
    color: var(--st-color-ink); }
  .st_alert--success {
    background: var(--st-color-emerald-10);
    border-color: var(--st-color-emerald-70);
    color: var(--st-color-emerald-70); }
  .st_alert--warning {
    background: var(--st-color-sunshine-10);
    border-color: var(--st-color-sunshine-70);
    color: var(--st-color-sunshine-70); }
  .st_alert--error {
    background: var(--st-color-lava-10);
    border-color: var(--st-color-lava-70);
    color: var(--st-color-lava-70); }
