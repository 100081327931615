/**
All CSS classes must be prefixed with "st_"
- only include CSS for this module
- favor CSS over using SASS features
- keep selectors flat: generally, only one element deep

*/
.st_loader {
  border-radius: 50%;
  display: inline-block;
  border: var(--st-spacer-xs) solid var(--st-color-stainless);
  border-top: var(--st-spacer-xs) solid var(--st-color-blurple-50);
  border-right: var(--st-spacer-xs) solid var(--st-color-blurple-50);
  -webkit-animation: spin 0.8s linear infinite;
          animation: spin 0.8s linear infinite; }
.st_loader--xl {
    width: var(--st-spacer-xl);
    height: var(--st-spacer-xl); }
.st_loader--lg {
    width: var(--st-spacer-lg);
    height: var(--st-spacer-lg); }
.st_loader--md {
    width: var(--st-spacer-md);
    height: var(--st-spacer-md);
    border-width: calc(var(--st-spacer-xs) / 2); }
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
