/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_token {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 1px var(--st-spacer-xs);
  border-radius: var(--st-border-radius);
  white-space: nowrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-decoration: none;
  font-weight: var(--st-font-weight-reg);
  text-transform: none; }
.st_token:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
.st_token--with-avatar {
    border-radius: 9px var(--st-border-radius) var(--st-border-radius) 9px; }
.st_token--caps {
    text-transform: uppercase; }
.st_token--blurple {
    color: var(--st-color-blurple-70);
    background: var(--st-color-blurple-10); }
.st_token--gray {
    color: var(--st-color-ink);
    background: var(--st-color-stainless); }
.st_token--rose {
    color: var(--st-color-rose-70);
    background: var(--st-color-rose-10); }
.st_token--lava {
    color: var(--st-color-lava-70);
    background: var(--st-color-lava-10); }
.st_token--tangerine {
    color: var(--st-color-tangerine-70);
    background: var(--st-color-tangerine-10); }
.st_token--sunshine {
    color: var(--st-color-sunshine-70);
    background: var(--st-color-sunshine-10); }
.st_token--emerald {
    color: var(--st-color-emerald-70);
    background: var(--st-color-emerald-10); }
.st_token--ice {
    color: var(--st-color-ice-70);
    background: var(--st-color-ice-10); }
.st_token__icon:not(:only-child) {
  margin-right: var(--st-spacer-xs); }
.st_token__dismiss {
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0;
  margin: 0 0 0 var(--st-spacer-xs);
  opacity: 0.8;
  cursor: pointer; }
.st_token__dismiss:hover {
    opacity: 1; }
.st_token__dismiss:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
.st_token__avatar {
  margin: -1px var(--st-spacer-xs) -1px -3px; }
