/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_label {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer; }
.st_label--fullWidth {
  width: 100%; }
.st_label.st_label--top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
.st_label.st_label--bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }
.st_label.st_label--left {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.st_label.st_label--right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.st_label__text {
  color: var(--st-color-ink);
  display: block; }
.st_label--top .st_label__text,
.st_label--bottom .st_label__text {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm);
  font-weight: var(--st-font-weight-bold); }
.st_label--left .st_label__text,
.st_label--right .st_label__text {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md); }
.st_label--bottom .st_label__text {
  margin-top: var(--st-spacer-xs); }
.st_label--top .st_label__text {
  margin-bottom: var(--st-spacer-xs); }
.st_label--left .st_label__text {
  margin-right: var(--st-spacer-sm); }
.st_label--right .st_label__text {
  margin-left: var(--st-spacer-sm); }
.st_formhelpertext {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm);
  color: var(--st-color-asphalt);
  margin: var(--st-spacer-xs) 0;
  padding: 0; }
.st_formhelpertext:last-child {
  margin-bottom: 0; }
.st_formhelpertext.st_formhelpertext--error {
  color: var(--st-color-lava-50); }
