/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_switch {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: calc(var(--st-spacer-md) * 2 + 2px);
  height: calc(var(--st-spacer-md) + 2px);
  background: var(--st-color-gray);
  border-radius: 9px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  cursor: pointer;
  position: relative; }
.st_switch::after {
    content: '';
    position: absolute;
    display: block;
    margin-top: 1px;
    margin-left: 1px;
    width: var(--st-spacer-md);
    height: var(--st-spacer-md);
    top: 0;
    left: 0;
    background: var(--st-color-white);
    border-radius: 50%;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    -webkit-transition: all 300ms;
    transition: all 300ms; }
.st_switch:checked {
    background: var(--st-color-blurple-50); }
.st_switch:checked::after {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); }
.st_switch--disabled {
    opacity: 0.5;
    pointer-events: none; }
.st_switch--invalid {
    background-color: var(--st-color-lava-30); }
.st_switch--invalid:checked {
    background-color: var(--st-color-lava-50); }
.st_switch:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
