@charset "UTF-8";
/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_checkbox {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: var(--st-spacer-md);
  height: var(--st-spacer-md);
  margin: 0;
  border-radius: var(--st-border-radius);
  cursor: pointer;
  display: inline-block;
  overflow: hidden; }
.st_checkbox::after {
    border: solid var(--st-color-stainless);
    border-width: 1px;
    content: '';
    overflow: hidden;
    width: var(--st-spacer-md);
    height: var(--st-spacer-md);
    font-size: var(--st-font-size-sm);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: var(--st-border-radius);
    background: var(--st-color-white);
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
.st_checkbox:checked::after {
    content: '✓';
    background: var(--st-color-blurple-50);
    border-color: var(--st-color-blurple-50);
    color: var(--st-color-white); }
.st_checkbox--disabled {
    opacity: 0.5;
    pointer-events: none; }
.st_checkbox--disabled::after {
    background-color: var(--st-color-cool); }
.st_checkbox--invalid::after {
    border-color: var(--st-color-lava-50);
    background-color: var(--st-color-lava-05); }
.st_checkbox--invalid:checked::after {
    border-color: var(--st-color-lava-50);
    background-color: var(--st-color-lava-50); }
.st_checkbox:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
