.st_tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: var(--st-spacer-mlg);
  border-bottom: 1px solid var(--st-border-color);
  width: 100%;
  overflow-x: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.st_tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  font-weight: var(--st-font-weight-med);
  line-height: var(--st-line-height-md);
  margin: 0 var(--st-spacer-md) 0 0;
  border: 0;
  background: none;
  padding: 0 0 var(--st-spacer-xs);
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  color: var(--st-color-ink); }

.st_tab:last-child {
  margin-right: 0; }

.st_tab.st_tab--selected {
  color: var(--st-color-blurple-50);
  cursor: default; }

.st_tab.st_tab--disabled {
  opacity: 0.5; }

.st_tab:focus {
  outline: none; }

.st_tab:focus::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    background-color: var(--st-color-blurple-20);
    height: 2px;
    width: 100%; }

.st_tab:focus:active {
  color: inherit; }

@media (hover: hover) {
  .st_tab:hover::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    background-color: var(--st-color-blurple-50);
    height: 2px;
    width: 100%; } }

.st_tab.st_tab--selected::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0;
  background-color: var(--st-color-blurple-50);
  height: 2px;
  width: 100%; }
