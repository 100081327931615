/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_textarea {
  border: solid var(--st-color-stainless);
  border-width: 1px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  border-radius: var(--st-border-radius);
  background: var(--st-color-white);
  color: var(--st-color-ink);
  padding: var(--st-spacer-sm);
  line-height: var(--st-font-line-height-md);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  -webkit-box-shadow: none;
          box-shadow: none;
  height: calc(3 * var(--st-module-button-height));
  resize: vertical;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_textarea:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
.st_textarea--disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: var(--st-color-cool); }
.st_textarea--invalid {
    border-color: var(--st-color-lava-50);
    background-color: var(--st-color-lava-05); }
