/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_input {
  border: solid var(--st-color-stainless);
  border-width: 1px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  height: var(--st-module-button-height);
  border-radius: var(--st-border-radius);
  background: var(--st-color-white);
  color: var(--st-color-ink);
  padding: var(--st-spacer-sm);
  line-height: var(--st-font-line-height-md);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.st_input:focus {
    border-color: var(--st-color-blurple-50);
    outline: none;
    -webkit-box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
            box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50); }
.st_input--disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: var(--st-color-cool); }
.st_input--invalid {
    border-color: var(--st-color-lava-50);
    background-color: var(--st-color-lava-05); }
.st_inputwrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: var(--st-color-white); }
.st_inputwrapper--disabled {
    background-color: var(--st-color-cool); }
.st_inputwrapper--invalid {
    background-color: var(--st-color-lava-05); }
.st_input.st_input--withicon {
  padding-left: calc(var(--st-spacer-mlg) + var(--st-spacer-xs));
  background: transparent;
  margin-left: calc(-1 * var(--st-spacer-mlg)); }
.st_input.st_input--withicon::-webkit-calendar-picker-indicator {
  display: none; }
.st_input__icon {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  color: var(--st-color-gray);
  height: var(--st-module-button-height);
  margin-left: var(--st-spacer-sm); }
.st_input:focus + .st_input__icon {
  color: var(--st-color-blurple-50); }
.st_input.st_input--invalid + .st_input__icon {
  color: var(--st-color-lava-50); }
