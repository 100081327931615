.st_breadcrumbs {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--st-color-blurple-50); }

.st_breadcrumbs__item--current {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-med);
  color: var(--st-color-blurple-50);
  text-decoration: none;
  cursor: normal;
  padding: 0 var(--st-spacer-xs); }
