.st_pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.st_pagination__ellipsis {
  color: var(--st-color-blurple-50);
  padding-top: 0.25rem; }

.st_pagination__first-button {
  margin-right: -0.175rem;
  padding-right: 0.25rem; }

.st_pagination__last-button {
  margin-left: -0.175rem;
  padding-left: 0.25rem; }

.st_pagination__prev-button {
  margin-left: -0.175rem; }

.st_pagination__next-button {
  margin-right: -0.175rem; }

.st_pagination__icon-first {
  margin-right: -0.3rem; }

.st_pagination__icon-second {
  margin-left: -0.3rem; }
