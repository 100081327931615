/**
 * Do not edit directly
 * Generated on Mon, 01 Mar 2021 16:32:53 GMT
 */
.st_text {
  font-family: var(--st-font-stack);
  display: block;
  text-decoration: none; }
.st_text > a:not(*[class]) {
    /* stylelint-disable-line plugin/selector-tag-no-without-class */
    color: currentColor;
    font-weight: inherit;
    text-decoration: none; }
.st_text--v-link {
    text-decoration: none;
    cursor: pointer; }
.st_text--v-link:hover {
      text-decoration: underline; }
.st_text--v-bodytext a:not(*[class]),
.st_text--v-note a:not(*[class]) {
  color: var(--st-color-blurple-50);
  text-decoration: none;
  font-weight: var(--st-font-weight-med); }
.st_text--v-bodytext a:not(*[class]):hover,
  .st_text--v-note a:not(*[class]):hover {
    text-decoration: underline; }
.st_text--color-inherit {
  color: inherit; }
.st_text--color-ink {
  color: var(--st-color-ink); }
.st_text--color-blurple {
  color: var(--st-color-blurple-50); }
.st_text--color-asphalt {
  color: var(--st-color-asphalt); }
.st_text--color-lava {
  color: var(--st-color-lava-50); }
.st_text--color-emerald {
  color: var(--st-color-emerald-50); }
.st_text--color-white {
  color: var(--st-color-white); }
.st_text--truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
.st_text--align-left {
  text-align: left; }
.st_text--align-center {
  text-align: center; }
.st_text--align-right {
  text-align: right; }
.st_text--align-justify {
  text-align: justify; }
.st_text--inline {
  display: inline; }
.st_text--block {
  display: block; }
.st_text--inherit {
  font-size: inherit; }
.st_text--sm {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-sm);
  line-height: var(--st-font-line-height-sm); }
.st_text--md {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md); }
.st_text--lg {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-lg);
  line-height: var(--st-font-line-height-lg); }
.st_text--xl {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-xl);
  line-height: var(--st-font-line-height-xl); }
.st_text--xxl {
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-xxl);
  line-height: var(--st-font-line-height-xxl); }
@media (min-width: 48rem) {
  .st_text--lg {
    font-size: var(--st-font-size-lg-bp-2);
    line-height: var(--st-font-line-height-lg-bp-2); }
  .st_text--xl {
    font-size: var(--st-font-size-xl-bp-2);
    line-height: var(--st-font-line-height-xl-bp-2); }
  .st_text--xxl {
    font-size: var(--st-font-size-xxl-bp-2);
    line-height: var(--st-font-line-height-xxl-bp-2); } }
.st_text--weight-reg {
  font-weight: var(--st-font-weight-reg); }
.st_text--weight-med {
  font-weight: var(--st-font-weight-med); }
.st_text--weight-bold {
  font-weight: var(--st-font-weight-bold); }
.st_text--weight-black {
  font-weight: var(--st-font-weight-black); }
.st_text--uppercase {
  text-transform: uppercase; }
